import { graphql } from "gatsby";
import React, { Component } from "react";

import Hero from "react-bulma-components/lib/components/hero/hero";
import Body from "react-bulma-components/lib/components/hero/components/hero-body";
import Container from "react-bulma-components/lib/components/container/container";
import Content from "react-bulma-components/lib/components/content/content";
import Heading from "react-bulma-components/lib/components/heading/heading";

import { Page } from "reusable-components";
import { ArrowLeft } from "reusable-components/ui/buttons";

import Logo from "../components/brand/logo";
import Articles from "../components/sections/blog-overview/articles-list";
import Footer from "../components/sections/footer";
import Author from "../components/ui/author";
import HorizontalDivider from "../components/ui/divider/horizontal";
import Styles from "./blog.module.scss";


export default class BlogOverview extends Component {
	static propTypes = {};

	static defaultProps = {};

	render () {
		const {
			blogOverview,
			generalData
		} = this.props.data.takeshape;

		const {
			siteMetadata
		} = this.props.data.site;

		return (
			<Page
				title={ `Blog | ${ generalData.titleAppendix }` }
				description={ blogOverview.description }
				absoluteUrl={ siteMetadata.siteUrl }
				author={ siteMetadata.author }
				absoluteTeaserImagePath={ siteMetadata.siteUrl }
				language={ siteMetadata.language }
				location={ {
					...generalData.location,
					locationName: generalData.companyName,
				} }>

				<div className={ Styles.header }>
					<ArrowLeft
						renderAs={ "a" }
						href={ "/" }
						className={ Styles.button }>
						Start
					</ArrowLeft>

					<Heading
						textAlignment={ "centered" }
						renderAs={ "div" }>
						<Logo className={ Styles.logo } />
					</Heading>
				</div>

				<Container>
					<Hero
						size={ "medium" }
						textAlignment={ "centered" }
						className={ Styles.hero }>

						<Body className={ Styles.body }>
							<Heading
								textAlignment={ "centered" }
								renderAs={ "h1" }
								className={ Styles.title }>
								<span>Blog</span>
							</Heading>

							<Content
								textAlignment={ "centered" }
								className={ Styles.description }
								dangerouslySetInnerHTML={ { __html: blogOverview.description } }>
							</Content>
						</Body>
					</Hero>

					<HorizontalDivider
						className={ "has-margin-bottom-xl" }
					/>

					<Articles />

					<HorizontalDivider
						className={ "has-margin-top-xl has-margin-bottom-xl" }
					/>

				</Container>

				<Footer />

				<Author />
			</Page>
		);
	}
}


export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
                language
                author
            }
        }

        takeshape {
			blogOverview: getBlogOverview {
				description
			}
	        
            generalData: getGeneralData {
                titleAppendix
                companyName
                description
                location {
                    latitude
                    longitude
                }
            }
        }
    }
`;