import { graphql, StaticQuery } from "gatsby";
import React, { Component } from "react";
import Container from "react-bulma-components/lib/components/container/container";
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group";

import DefaultBtn from "reusable-components/ui/buttons";

import { getArticleUrl } from "../../../../helper"
import ArticleTile from "../../../ui/tiles/article-tile";
import Styles from "./index.module.scss";


const HOC = () =>
	<StaticQuery
		query={ graphql`
			query {
	            takeshape {
	                getBlogartikelList(sort: {field: "_updatedAt", order: "desc"}) {
	                    items {
	                        id: _id
	                        updated: _updatedAt
	
	                        title
	                        description
	                    }
	                }
	            }
	        }
		` }
		render={ data =>
			<Articles articles={ data.takeshape.getBlogartikelList.items } />
		}
	/>;


class Articles extends Component {
	handleRandomArticleBtnClick () {
		const index = Math.floor(Math.random() * this.props.articles.length);
		window.open(getArticleUrl(this.props.articles[index].title), "_self");
	}

	render () {
		return (
			<>
				<ButtonGroup
					position={ "centered" }>
					<DefaultBtn
						onClick={ this.handleRandomArticleBtnClick.bind(this) }
						className={ Styles.randomArticleBtn }>

						<i className={ `icon-shuffle ${ Styles.icon }` }/>
						<span>Zufälliger Artikel</span>

					</DefaultBtn>
				</ButtonGroup>

				<Container className={ Styles.articlesContainer }>
					<ArticleTile
						isBrandNew
						className={ Styles.brandNewArticle }
						articleId={ this.props.articles[0].id }
					/>

					{ this.props.articles.splice(1).map(({ id }) =>
						<ArticleTile
							key={ id }
							articleId={ id }
							isMinimalistic
							className={ Styles.defaultArticle }
						/>
					) }
				</Container>
			</>
		);
	}
}

export default HOC;
